import React,{useState,useEffect} from 'react'
import utils from '../lib/util'
import SelectPicks from './components/SelectPicks'
import sessionManager from '../lib/session'
import { get } from '../lib/call-api' 
import useIsMountedRef from '../common/hooks/useIsMountedRef'

const OnBehalf = () => {
  const [status,setStatus] = useState('editing')
  const [onBehalfOf, setOnBehalfOf] = useState(null)
  const sessionData = sessionManager.getSession()
  
  const whosPicks = () => {
    const name = onBehalfOf.nickname
    if(name[name.length-1] === 's') {
      return `${name}'`
    } else {
      return `${name}'s`
    }
  }

  return (
    <>
    {
      sessionData && 
      <>
        <h1>Make another user's picks for week {sessionData.thisWeek.weekNum}</h1>

        {
          sessionData.pool && sessionData.pool.userIsAdmin === true && status !== 'saved' &&
          <section className="call-out">
            <h2>Who do you want to make picks for? <UserList handleChange={setOnBehalfOf}/></h2>
          </section>
          
        }

        {
          onBehalfOf && 
          <SelectPicks userMakingPicks={onBehalfOf} thisWeek={sessionData.thisWeek} whosPicks={whosPicks} onStatusChange={setStatus} />
        }
      </>
    }
    </>
  )
}

const UserList = ({handleChange}) => {

  const [userList, setUserList] = useState([])
  const [loading, setLoading] = useState(true)
  const sessionData = sessionManager.getSession()
  const isMounted = useIsMountedRef()


  useEffect(() => {
    const onUserListGet = (response) => {
      if(isMounted.current === true) {
        setUserList(response.users.filter(user => user.userId !== sessionData.userData.userId))
        setLoading(false)
      }
      
    }
  
    const onUserListError = (error) => {
      console.log(error)
    }

    if(userList.length === 0 && loading === true) {
      get(`/api/users/list/${sessionData.pool.poolId}`,onUserListGet,onUserListError,setLoading,'get the users')
    }

    return () => { isMounted.current = false }
  },[userList,loading,sessionData.userData.userId,isMounted])

  const onSelect = (event) => {
    const selectedUserId = parseInt(event.target.value)
    handleChange(userList.filter(user => user.userId === selectedUserId)[0])
  }
  return (
      loading === false && userList.length > 0 &&
      <select onChange={(event) => onSelect(event)}>
        <option value='-1'>Select a user</option>
        {
          userList.map(user => 
            <option value={user.userId} key={`switch-user-${user.userId}`}>{utils.getUserName(user)}</option>
          )
        }
      </select>

  )

}

export default OnBehalf