import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom"
import sessionManager from '../../lib/session'
import BrowserVersion from '../../common/components/BrowserVersion'
import User from './User'

const NavigationBar = ({className,sessionUpdated,setSessionUpdated}) => {
  const [sessionData,setSessionData] = useState(sessionManager.getSession())

  useEffect(() => {
    
    if(sessionUpdated === true) {
      setSessionData(sessionManager.getSession())
      setSessionUpdated(false)
    }
    
  },[sessionUpdated])

  const defaultProfilePanelClass = 'nav-section'
  const [profilePanelClass,setProfilePanelClass] = useState(defaultProfilePanelClass)
  
  const handleLogout = (e) => {
    
    sessionManager.clearSession(false)
    setProfilePanelClass('')
    
  } 

  const getNavClassName = (section) => {
    const map = [
      {
        navSectionId: 'home',
        path:'/home'
      },
      {
        navSectionId: 'picksAndGames',
        path:'/games'
      },
      {
        navSectionId: 'quiz',
        path:'/quiz'
      },
      {
        navSectionId: 'standingsResults',
        path:'/standings'
      },
    ]
  }

  const toggleProfilePanel = () => {
    setProfilePanelClass(profilePanelClass === defaultProfilePanelClass ?  `${defaultProfilePanelClass} show` : defaultProfilePanelClass)
  } 

 
  return (
    
    <nav className={className}>
      
        
        <div id="nav-collapse">

          <div className='nav-section'>
          {
              sessionData.thisWeek && sessionData.userData && sessionData.season &&
              <>
                  <div>{sessionData.season.seasonYear} {sessionData.season.sportsLeague} {sessionData.season.seasonType.name}</div>
                  {
                    sessionData.thisWeek.weekNum && 
                    <div><strong>Week {sessionData.thisWeek.weekNum}</strong></div>
                  }
              </>
            }
          </div>
          
          <div className='nav-section'>
            <ul id='primary-nav'>
              <li>
                <Link className="nav-link" to="/">Home</Link>
              </li>

              
              
              {
                sessionData.pool && 
                  <>
                  {
                      sessionData.pool.userIsAdmin === true && 
                      
                        <li><Link className="nav-link" to="/picks">Games &amp; Picks</Link></li>
                  }
                  {
                      sessionData.pool.userIsAdmin === false && 
                      
                        <li><Link className="nav-link" to="/picks">Picks</Link></li>
                  }   
                   
                  
                </>
              }

              {
                sessionData.pool && sessionData.pool.userIsAdmin === true && 
                <li>
                  <Link className="nav-link" to="/quiz">Sports Quiz</Link>
                </li>
              }

              {
                sessionData.pool && sessionData.pool.userIsAdmin === false && 
                <li>
                  <Link className="nav-link" to="/quiz/picks/select">Sports quiz</Link>
                </li>
              }
              
              <li>
                <Link className="nav-link" to="/standings">Standings</Link>
              </li>
              
              {
                sessionData.pool && sessionData.pool.userIsAdmin === true && 
                <li>
                  <Link className="nav-link" to="/players">Players</Link>
                </li>
              }


            </ul>
          </div>
          {
              sessionData.accessToken !== null && 
                <div id='profile-nav' className={profilePanelClass}>
                  <label className='heading'>Logged in as:</label>
                  <User userData={sessionData.userData} handleClick={toggleProfilePanel} showArrow={true} />
                  <div id='profile-panel'>
                    <Link to="#" onClick={handleLogout}>Logout</Link>
                  </div>

                  <BrowserVersion />
                </div>
            }  
            
          {
            sessionData.accessToken === null &&
              <div className='nav-section'><Link to="/login">Login</Link></div>
          }
        </div>
    </nav>
  )
    
  
}

export default NavigationBar