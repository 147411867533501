import React,{useState, useEffect} from 'react'
import { useModalState  } from '../common/components/Modal.Context'
import utils from '../lib/util'
import DisplayQuestion from './components/DisplayQuestion'
import EditQuestion from './components/EditQuestion'
import Alert from '../common/components/Alert'
import SubmitButton from '../common/components/SubmitButton'
import Modal from '../common/components/Modal'
import sessionManager from '../lib/session'
import {get,post} from '../lib/call-api'
import WeekList from '../common/components/WeekList'
import useIsMountedRef from '../common/hooks/useIsMountedRef'

import './css/quiz-managing.scss'

const ManageQuiz = () => {
  const [quizQuestions,setQuizQuestions] = useState([])
  const [originalQuizQuestions,setOriginalQuizQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [savedQuiz, setSavedQuiz] = useState()
  const [pageLevelErrors,setPageLevelErrors] = useState([])
  const [blockAccessMessage,setBlockAccessMessage] = useState(null)
  const [,setManageQuizErrors] = useState([])

  const [mode,setMode] = useState()
  const sessionData = sessionManager.getSession()
  const [ modalState, modalDispatch ] = useModalState()

  const [quizWeek,setQuizWeek] = useState(sessionData.thisWeek)

  const isMounted = useIsMountedRef()


  useEffect(() => {
    setMode()
    const onGet = (response) => {
      setIsLoading(false)

      if(isMounted.current === true) {
        if(response.quiz.quizQuestions) {
          setQuizQuestions(response.quiz.quizQuestions)
          setOriginalQuizQuestions(response.quiz.quizQuestions)
        } else {
          setQuizQuestions([])
          setOriginalQuizQuestions([])
        }
      }
     
    }
    const onGetError = (error) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        if(error === '401') {
          setBlockAccessMessage("Only admins can manage the quiz.")
          setPageLevelErrors()
        } else {
          setBlockAccessMessage(null)
          setPageLevelErrors(() => [
            error.message
          ])
        }
      }
    }

    get(`/api/quiz/manage/week/${quizWeek.weekId}`,onGet,onGetError)

    return () => { isMounted.current = false}

  },[quizWeek,isMounted])

  const enterAddMode = (event) => {
    event.preventDefault()
    reset()
    setMode('add')
    toggleModal()
  }
  const toggleModal = () => {
    modalDispatch({type: 'toggleIsOpen', payload: null})
  }

  const saveQuestionToList = (questionToSave) => {
      const questionId = questionToSave.questionId
      if(typeof questionId !== "undefined" && questionId !== null) {
        setQuizQuestions(
          quizQuestions.map( previous => {
            if(previous.questionId !== null && previous.questionId === questionId) {
              return questionToSave
            } else {
              return previous
            }
          })
        )
      } else {
        questionToSave.questionId = utils.createTempId()
        setQuizQuestions(
          previous => [
            ...previous,
            questionToSave
          ]
        )
      }
      reset()
  }
  
  const reset = (resetToOriginal) => {
    if(resetToOriginal === true) {
      setQuizQuestions(() => [...originalQuizQuestions])
    } 
    setManageQuizErrors([])
    setIsLoading(false)
    setQuestionEditing({})
    setMode()
    if(modalState.isOpen === true) toggleModal()
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    const onPost = (response) => {
      reset()
      setSavedQuiz(response.quiz)
    }

    const onPostError = (error) => {
      setManageQuizErrors([error.message])
    }

    const reqBody = {
      "quiz": {
        "weekId": quizWeek.weekId,
        "quizQuestions": quizQuestions,
        "originalQuizQuestions": originalQuizQuestions
      }
    }

    post('/api/quiz/save',onPost,onPostError,setIsLoading,'save the quiz',null,reqBody)
    
  
  }

  const [questionEditing,setQuestionEditing] = useState() 

  const enterEditMode = (event,questionData) => {
    event.preventDefault()
    setMode('edit')
    toggleModal()
    setQuestionEditing(questionData)
  }


  const cancel = (event) => {
    event.preventDefault()
    reset()
  }

  const deleteQuestion = (event,questionToDelete) => {
    event.preventDefault()
    setQuizQuestions(
      () => {
        return quizQuestions.filter(question => question.questionId !== questionToDelete.questionId)
      }
    )
  }

  return (
    <div id="manage-quiz" className="page">
      {
        savedQuiz &&
        <>
          <h1>Your quiz questions have been saved.</h1>
          <Alert type="success" message={`Successfully saved the quiz for week ${quizWeek.weekNum}`} />          
          <QuizSummary quizData={savedQuiz} />
        </>
      }

      {
        !savedQuiz && quizQuestions && 
        <>
          <h1>Manage the Quiz</h1>

          { 
            blockAccessMessage && 
            <Alert type='error' message={blockAccessMessage} />
          }

          {
            !blockAccessMessage && 
            <form onSubmit={(event) => handleSubmit(event)} id='manage-quiz'>
              {
                pageLevelErrors.length > 0 && 
                <Alert type='error' message={pageLevelErrors} />
              }
    
            <section className="week-list-container">
              <label>Select a week:</label> <WeekList   
                  seasonId={sessionData.season.seasonId}
                  selectedWeek={quizWeek}
                  handleChange={setQuizWeek} />
                  {
                    quizQuestions.length>  0 &&
                    <p>Note: if you change the week you will lose any unsaved changes below.</p>
                  }
             
            </section>


            {
                quizWeek.weekNum && 
                <>
                  {
                    quizQuestions.length === 0 &&
                    <p className="no-results">There are no quiz questions entered for Week {quizWeek.weekNum}.</p>
                  }
        
                    <p>
                      <button className="btn btn-icon btn-add" onClick={(event) => {event.preventDefault();enterAddMode(event);}}>Add a question</button>
                    </p>
                
                </>
              }          
              
    
              {
                mode === 'add' && 
                <Modal heading="Add a question">
                  <section className='adding-question'>
                    <EditQuestion questionData={questionEditing} onSave={saveQuestionToList} onCancel={cancel} />
                  </section>
                  </Modal>
              }
    
              <section className='existing-questions'>
                {
                  quizQuestions.length > 0 && 
                  quizQuestions.map((thisQuestion,questionCtr) => 
                  <span key={`question-modal-${thisQuestion.questionId}`}>
                    {
                      questionEditing && questionEditing.questionId === thisQuestion.questionId &&
                      <Modal heading="Edit a question" onClose={cancel}>
                        <EditQuestion questionData={questionEditing} onSave={saveQuestionToList} onCancel={cancel} />
                      </Modal>
                    }
    
                    
                    <DisplayQuestion key={`display-question-${questionCtr}`} ctr={questionCtr} question={thisQuestion} onSelection={null} onEdit={enterEditMode} onDelete={deleteQuestion} />
                    
                   </span>
                  )
                }
                
              </section>
    
              {
                quizQuestions.length > 0  && (!mode ||( mode && mode !== 'edit')) && 
              <div className="button-container">
                <div className="form-status"></div>
                <div>
                  {/*<button className='btn btn-link' onClick={(event) => cancelAllChanges(event)}>Cancel All Changes</button>*/}
                  <SubmitButton isLoading={isLoading} actionTextOverride={`Save quiz for week ${quizWeek.weekNum}`}/>
                </div>
              </div>
              }
            </form>
          }
        </>
          
      }
    </div>
    
  )
}


const QuizSummary = ({quizData}) => {
  return(
    <div>

      {
        quizData.quizQuestions.map((question,questionCtr) => 
          <DisplayQuestion key={`question-summary-${questionCtr}`} question={question} ctr={questionCtr} />
        )
      }
    </div>
  )
}


export default ManageQuiz