import React, {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
import {get} from '../../lib/call-api'
import sessionManager from '../../lib/session'
import StatusItem from './StatusItem'
import './AdminStatusReport.scss'
import useIsMountedRef from '../hooks/useIsMountedRef'

const AdminStatusReport = () => {
  const sessionData = sessionManager.getSession()
  const [gamesWithoutSpreads,setGamesWithoutSpreads] = useState()
  const [countGamesThisWeek,setCountGamesThisWeek] = useState()

  const [countGamesLastWeek,setCountGamesLastWeek] = useState()
  const [playersWithoutPicks,setPlayersWithoutPicks] = useState()
  const [gamesWithoutWinners,setGamesWithoutWinners] = useState()
  const [lastWeekNum,setLastWeekNum] = useState()
  const [thisWeekNum,setThisWeekNum] = useState()
  const [quizQuestionCount,setQuizQuestionCount] = useState()

  const [isLoading,setIsLoading] = useState(true)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    const onGet = (response) => {
      if(isMounted.current === true) {
        setIsLoading(false)
        setGamesWithoutSpreads(response.thisWeek.games.countWithoutSpreads)
        setPlayersWithoutPicks(response.thisWeek.games.countUsersWithoutPicks)
        setCountGamesThisWeek(response.thisWeek.games.totalCount)
        setGamesWithoutWinners(response.lastWeek.games.countWithoutWinners)
        setCountGamesLastWeek(response.lastWeek.games.totalCount)
        setLastWeekNum(response.lastWeek.weekNum)
        setThisWeekNum(response.thisWeek.weekNum)

        setQuizQuestionCount(response.thisWeek.quiz.countQuestions)
     }
    }
    if(sessionData.thisWeek && sessionData.thisWeek.weekId) {
      get(`/api/status/admin/week/${sessionData.thisWeek.weekId}`, onGet, null)
    }

    return () => { isMounted.current = false }

  },[isMounted])

return (

<div id="admin-status-report">
{
    sessionData.thisWeek 
    && sessionData.thisWeek.weekId 
    && isLoading === false 
    && sessionData.pool 
    && sessionData.pool.userIsAdmin === true 
    && !isNaN(gamesWithoutSpreads)
    && !isNaN(countGamesThisWeek)
    && !isNaN(thisWeekNum)
    && !isNaN(playersWithoutPicks)
    && !isNaN(quizQuestionCount)
    &&
    <div className="callout prominent">
      <h3>Status report</h3>
     
        <section>
          
          <h4 id="currentWeekHeading">Week {thisWeekNum}</h4>
          <ul>
           
              <SpreadStatus gamesWithoutSpreads={gamesWithoutSpreads} countGamesThisWeek={countGamesThisWeek} weekNum={thisWeekNum} />
           
            {
              gamesWithoutSpreads !== countGamesThisWeek && 
              <PicksStatus playersWithoutPicks={playersWithoutPicks} />
            }

            <QuizStatus quizQuestionCount={quizQuestionCount} weekNum={thisWeekNum} />
            
          </ul>
        </section>
        {
          lastWeekNum && 
          <section>
          <h4 id="previousWeekHeading">Week {lastWeekNum}</h4>
          <ul>
            <WinnersStatus gamesWithoutWinners={gamesWithoutWinners} weekNum={lastWeekNum} />
          </ul>
          
        </section>
        }
      </div>

}

</div>
)
  
}

const SpreadStatus = ({gamesWithoutSpreads,countGamesThisWeek}) => {

  const [message,setMessage] = useState()
  const [isOk,setIsOk] = useState()
  const [actionText,setActionText] = useState()
  const [actionPath,setActionPath] = useState()
  const [htmlId,setHtmlId] = useState()

  useEffect(() => {
    if(gamesWithoutSpreads === 0) {
      setIsOk(true)
      setMessage("Spreads have been entered for all the games this week")
      setHtmlId("spreadsOk")
    } else if (gamesWithoutSpreads === countGamesThisWeek) {
      setIsOk(false)
      setMessage(`No spreads have been entered`)
      setActionText('Enter spreads')
      setActionPath('/games/manage')
      setHtmlId("noSpreads")
    } else {
      setIsOk(false)
      setMessage(`Spreads not been entered for ${gamesWithoutSpreads} games`)
      setActionText('Enter spreads')
      setActionPath('/games/manage')
      setHtmlId("partialNoSpreads")
    }
  },[])
    
  return(
    isOk !== null && message !== null && 
    <StatusItem isOk={isOk}><span id={htmlId}>{message}</span>
    {
        actionPath && actionText &&
        <>&nbsp;<Link to={actionPath}>{actionText}</Link></>
      }
    </StatusItem>
  )
}

const plural = (resultCount) => {
  if(resultCount > 1) {
    return "s"
  } else {
    return ""
  }
}

const PicksStatus = ({playersWithoutPicks}) => {
  const [message,setMessage] = useState()
  const [isOk,setIsOk] = useState()
  const [actionText,setActionText] = useState()
  const [actionPath,setActionPath] = useState()
  const [htmlId,setHtmlId] = useState()

  useEffect(() => {
    if(playersWithoutPicks === 0) {
     setIsOk(true)
      setMessage('All players have made their picks for the week.')
      setHtmlId("picksOk")
    } else {
      setIsOk(false)
      const hasHave = (playersWithoutPicks > 1) ? ' have' : ' has'
      setMessage(`${playersWithoutPicks} player${plural(playersWithoutPicks)} ${hasHave} not made their picks yet.`)
      setActionText('See the report')
      setActionPath('/picks/status')
      setHtmlId("picksNotOk")
    }
  },[])
  
    
  return(
    isOk !== null && message !== null && 
    <StatusItem isOk={isOk}>
      <span id={htmlId}>{message}</span>
      {
        actionPath && actionText &&
        <>&nbsp;<Link to={actionPath}>{actionText}</Link></>
      }
    </StatusItem>
  )
}

const WinnersStatus = ({gamesWithoutWinners}) => {
  const [message,setMessage] = useState()
  const [isOk,setIsOk] = useState()
  const [actionText,setActionText] = useState()
  const [actionPath,setActionPath] = useState()
  const [htmlId,setHtmlId] = useState()

  useEffect(() => {
    if(gamesWithoutWinners === 0) {
      setIsOk(true)
      setMessage( `All the winners have been entered`)
      setHtmlId("winnersOk")

    } else {
      setIsOk(false)
      const contraction = (gamesWithoutWinners > 1) ? `don't` :`doesn't`
      setMessage( `${gamesWithoutWinners} game${plural(gamesWithoutWinners)} ${contraction} have any winners yet.`)
      setActionText('Enter the winners')
      setActionPath('/games/winners')
      setHtmlId("winnersNotOk")
    }
  },[])

  
  return (
    isOk !== null && message !== null && 
    <StatusItem isOk={isOk}>
      <span id={htmlId}>{message}</span>
      {
        actionPath && actionText &&
        <>&nbsp;<Link to={actionPath}>{actionText}</Link></>
      }
    </StatusItem>
    
  )
  
}

const QuizStatus = ({quizQuestionCount}) => {
  const [message,setMessage] = useState()
  const [isOk,setIsOk] = useState()
  const [actionText,setActionText] = useState()
  const [actionPath,setActionPath] = useState()
  const [htmlId,setHtmlId] = useState()

  useEffect(() => {
    if(quizQuestionCount > 0) {
     setIsOk(true)
     const verb = (quizQuestionCount > 1) ? ' are' : ' is'
      setMessage(`There ${verb} ${quizQuestionCount} quiz question${plural(quizQuestionCount)} entered`)
      setHtmlId("quizOk")
    } else {
      setIsOk(false)
      setMessage(`There aren't any quiz questions entered yet`)
      setActionText('Enter the quiz')
      setActionPath('/quiz/manage')
      setHtmlId("quizNotOk")
    }
  },[])
  
    
  return(
    isOk !== null && message !== null && 
    <StatusItem isOk={isOk}>
      <span id={htmlId}>{message}</span>
      {
        actionPath && actionText &&
        <>&nbsp;<Link to={actionPath}>{actionText}</Link></>
      }
    </StatusItem>
  )
}

export default AdminStatusReport